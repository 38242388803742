<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header justify-content-between">
            <div class="card-title">Invoice Detail</div>
          </div>
          <div class="card-body">
            <b-form v-if="!is_loading" class="row">
              <b-form-group label="Invoice Date" class="col-lg-6">
                <el-input disabled v-model="form.invoice_date"></el-input>
              </b-form-group>
              <b-form-group label="No. Invoice" class="col-lg-6">
                <el-input disabled v-model="form.invoice_number"></el-input>
              </b-form-group>
              <b-form-group label="Invoice File" class="col-lg-6">
                <div class="row">
                  <div class="col-lg-1">
                    <font-awesome-icon :icon="['far', 'file-pdf']" size="2x"/>
                  </div>
                  <div class="col-lg-8">
                    <a :href="form.invoice_url" target="_blank">Download</a>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label="Recon File" class="col-lg-6">
                <div class="row">
                  <div class="col-lg-1">
                    <font-awesome-icon :icon="['far', 'file-excel']" size="2x"/>
                  </div>
                  <div class="col-lg-8">
                    <a :href="form.recon_url" target="_blank">Download</a>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label="Set Status" v-if="form.status_detail.status_id === 'waiting_payment'">
                <el-select v-model="action">
                  <el-option value="confirm" label="Confirm">
                    <el-button @click="confirm" class="mr-2 ml-2" size="small" type="primary">Paid</el-button>
                  </el-option>
                  <el-option value="set_status" label="Set Status">
                    <el-button @click="setStatus" class="mr-2 ml-2" size="small" type="primary">Waiting Transfer</el-button>
                  </el-option>
                  <el-option value="cancel" label="Cancel">
                    <el-button @click="cancel" class="mr-2 ml-2" size="small" type="primary">Cancel</el-button>
                  </el-option>
                </el-select>
              </b-form-group>
            </b-form>
            <!-- <div v-if="form.status_detail.status_id === 'waiting_payment'">
              <el-button @click="confirm" class="mr-2 ml-2" size="small" type="primary">Confirm</el-button>
              <el-button @click="cancel" size="small" class="mr-2">Cancel</el-button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { MERCHANT_INVOICE_LIST, INVOICE_SUBMIT } from '@/store/modules/merchants';

export default {
  name: 'MerchantInvoiceDetail',
  metaInfo: {
    title: 'Merchant Apps Invoice',
  },
  data() {
    return {
      title: 'Invoice',
      is_loading: false,
      form: {
        invoice_date: moment().add(1, 'week').format('DD-MMM-YYYY'),
        invoice_number: 'INV/2023001/VTNY',
        invoice_url: '',
        recon_url: '',
      },
      action:''
    };
  },
  mounted() {
    this.invoiceDetail();
  },
  methods: {
    async invoiceDetail() {
      this.is_loading = true;
      await this.$store.dispatch(MERCHANT_INVOICE_LIST, {
        id: 'all',
        query: {
          invoice_id: this.$route.params.id,
        },
      }).then(({ data: response }) => {
        // console.log(response);
        this.form = response.rows[0];
        this.form.invoice_date = moment(this.form.created_at).format('DD MMM YYYY');
      });
      this.is_loading = false;
    },
    async confirm(){
      this.is_loading = true;
      this.$confirm('Are you sure want to confirm this invoice?', 'Confirmation', {
        type: 'info',
        confirmButtonText: 'Yes',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            // this.doCreatePromo();
            instance.confirmButtonLoading = true;
            try {
              await this.$store.dispatch(INVOICE_SUBMIT, {
                id: this.$route.params.id,
                query: {
                  action: 'paid' 
                },
              });
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Confirmation success',
              });
            } catch (error) {
              this.is_loading = false;
              throw error
            }
            instance.confirmButtonLoading = false;
          }
          done();
          await this.invoiceDetail();
        },
      });
    },
    async setStatus(){
      this.is_loading = true;
      this.$confirm('Are you sure want to confirm this invoice?', 'Confirmation', {
        type: 'info',
        confirmButtonText: 'Yes',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            // this.doCreatePromo();
            instance.confirmButtonLoading = true;
            try {
              await this.$store.dispatch(INVOICE_SUBMIT, {
                id: this.$route.params.id,
                query: {
                  action: 'waiting_transfer' 
                },
              });
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Confirmation success',
              });
            } catch (error) {
              this.is_loading = false;
              throw error
            }
            instance.confirmButtonLoading = false;
          }
          done();
            await this.invoiceDetail();
        },
      });
    },
    cancel(){
      this.$router.push({
        name: 'Merchants App Invoices'
      });
    }
  },
};
</script>